import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button, FormGroup, TextField } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="connect" lightnessTheme="light">
      <Helmet>
        <title>Daya Selaras Group - Connect</title>
      </Helmet>
      <div className={styles.jumbotron} style={{backgroundImage: "url('/static/images/photos/careers-2.jpg')"}}>
        <div className={styles.fence}>
          <div className={styles.cksSlide}>
            <div className={styles.title}>
              CAREER <br />OPPORTUNITY
            </div>
            <div className={styles.info}>
              Daya Selaras Group offers opportunities for professional growth and development. We strive to be fully engaging with all our employees. Our company motto “Together We Win” aims to encourage our workers to contribute in the attainment of our company vision.
            </div>
            <a href="mailto:hrd_recruitment@ptdck.com" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">JOIN US</Button></a>
          </div>
        </div>
      </div>
      <div className={styles.jumbotron} style={{backgroundImage: "url('/static/images/photos/management-trainee.jpg')"}}>
        <div className={styles.fence} style={{alignItems: "flex-start"}}>
          <div className={styles.cksSlide} style={{paddingRight: "0"}}>
            <img src="/static/images/ptdck-logo.png" className={styles.logo} />
            <div className={`${styles.title}`}>
              MANAGEMENT <br />TRAINEE PROGRAM
            </div>
            <div className={`${styles.info}`} style={{maxWidth: "600px", fontWeight: "normal"}}>
            <div>Daya Cipta Kemasindo is looking for fresh graduates (S1 or D3) who are enthusiastic to gain new skills and experiences that are required for further managerial positions. They will receive training from our experienced workers in order to acquire the knowledge of methods and procedures essential to the performance of their duties. </div>
            <div>The training will also facilitate subsequent transferability between departments and provide greater promotional opportunities.</div>
            <br />
            <div>This program runs for 6 months with the possibility of recruitment and is offered within all departments in our company such as Production, Human resources, Finance, Marketing and several more.</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.jumbotron} style={{backgroundImage: "url('/static/images/photos/get-in-touch.jpg')"}}>
        <div className={styles.fence}>
            <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                <div className={`${styles.titleWithDescription} ${styles.titleWithDescription__getInTouch}`} style={{maxWidth: "unset"}}>
                  <div className={styles.title}><span>GET IN TOUCH</span></div>
                  <div className={styles.info} style={{maxWidth: "unset"}}>
                    We are looking forward to work together to :
                    <ul>
                      <li>Provide sustainable solution in waste management system</li>
                      <li>Increase society awareness and education on recycling</li>
                      <li>Increase domestic waste recovery rate and standards</li>
                      <li>Collaborate with local waste paper collectors</li>
                    </ul>
                  </div>
                  <a href="mailto:hrd_recruitment@ptdck.com" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">CONTACT US</Button></a>
                </div>
            </div>
        </div>
      </div>
    </Template>
  }
}

export default IndexPage
